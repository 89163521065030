
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.main::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url("../../images/abstract-bg.png") 0 0 repeat;
  transform: rotate(10deg);
  -webkit-filter: blur(85px);
  -moz-filter: blur(85px);
  -o-filter: blur(85px);
  -ms-filter: blur(85px);
  filter: blur(85px);
  max-height: 100vh;
}

.logo {
  width: 154px;
  height: 95px;
}

.buttons {
  display: flex;
  gap: 24px;
  margin-top: 12px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  height: fit-content;
  min-width: 450px;
  max-width: 650px;
  padding: 40px;
  width: 45%;
}

.forgotPassword {
  align-self: flex-start;
}

.loginButton, .registerButton {
  max-width: 400px;
}

.MuiButtonBase-root.MuiTab-root {
  flex: 1;
}

